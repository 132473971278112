import React, { Component, Fragment } from "react";
import axios from "axios";

import Config from "../../config";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import LightCard from "./LightCard";

class Lights extends Component {
  constructor() {
    super();
    this.state = {
      lights: []
    };
  }

  getLights = async () => {
    try {
      const res = await axios.get(
        "http://" + Config.PhilipsHueAPI + "/api/" + Config.username + "/lights"
      );
      const { data } = await res;
      const lights = Object.values(data);

      this.setState({ lights: lights });
    } catch (e) {
      console.log(e);
    }
  };

  async componentDidMount() {
    await this.getLights();
    setInterval(async () => {
      await this.getLights();
    }, 500);
  }

  render() {
    const { lights } = this.state;

    return (
      <Container>
        <Box py={6}>
          <Grid container spacing={1}>
            {lights.map((el, i) => (
              <Fragment key={i}>
                <LightCard
                  id={i + 1}
                  title={el.name}
                  status={el.state.on}
                  reachable={el.state.reachable}
                  data={el}
                />
              </Fragment>
            ))}
          </Grid>
        </Box>
      </Container>
    );
  }
}

export default Lights;
