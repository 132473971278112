import React, { Component } from "react";
import axios from "axios";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Config from "../config";

class KaKu extends Component {
  constructor() {
    super();
    this.state = {
      kaku: []
    };
  }

  getKaKu = async () => {
    const res = await axios.get(
      "http://" +
        Config.DomoticzAPI +
        "/json.htm?type=devices&filter=light&used=true&order=Name"
    );
    const { data } = await res;
    const kaku = data.result;

    const kakuLights = kaku.filter(function(light) {
      return light.HardwareName === "433mhz Transmitter";
    });

    this.setState({ kaku: kakuLights });
  };

  async componentDidMount() {
    await this.getKaKu();
  }
  render() {
    const { kaku } = this.state;

    return (
      <div>
        <Container>
          <Box py={6}>
            <p>Lampen Overig</p>
            <ul>
              {kaku.map((el, i) => (
                <li key={i}>{el.Name}</li>
              ))}
            </ul>
          </Box>
        </Container>
      </div>
    );
  }
}

export default KaKu;
