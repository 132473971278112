import React, { Component } from "react";
import axios from "axios";

import Slider from "@material-ui/core/Slider";

import Config from "../../config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { SliderPicker } from "react-color";

library.add(fas);

// const StyledSwitch = withStyles({
//   switchBase: {
//     color: grey[300],
//     "&$checked": {
//       color: lightGreen["A400"]
//     },
//     "&$checked + $track": {
//       backgroundColor: lightGreen[500]
//     }
//   },
//   checked: {},
//   track: {}
// })(Switch);

class LightSettings extends Component {
  constructor() {
    super();
    this.state = {
      on: false,
      lightColor: "#fff"
    };
    this.setBrightness = this.setBrightness.bind(this);
    this.setColor = this.setColor.bind(this);
  }

  onClose = e => {
    this.props.onClose && this.props.onClose(e);
    document.body.style.overflow = "scroll";
  };

  setBrightness(event, value) {
    const el = event.target;
    const id = el.parentElement.dataset.id;
    axios.put(
      "http://" +
        Config.PhilipsHueAPI +
        "/api/" +
        Config.username +
        "/lights/" +
        id +
        "/state",
      { bri: value }
    );
  }

  setColor(color, event) {
    const el = event.target;
    const elparent =
      el.parentElement.parentElement.parentElement.parentElement.parentElement;
    const id = elparent.dataset.id;

    this.setState({ lightColor: color.hex });
    let red = color.rgb.r / 255;
    let green = color.rgb.g / 255;
    let blue = color.rgb.b / 255;

    //Apply a gamma correction to the RGB values, which makes the color more vivid and more the like the color displayed on the screen of your device
    red =
      red > 0.04045
        ? Math.pow((red + 0.055) / (1.0 + 0.055), 2.4)
        : red / 12.92;
    green =
      green > 0.04045
        ? Math.pow((green + 0.055) / (1.0 + 0.055), 2.4)
        : green / 12.92;
    blue =
      blue > 0.04045
        ? Math.pow((blue + 0.055) / (1.0 + 0.055), 2.4)
        : blue / 12.92;
    //RGB values to XYZ using the Wide RGB D65 conversion formula
    let X = red * 0.664511 + green * 0.154324 + blue * 0.162028;
    let Y = red * 0.283881 + green * 0.668433 + blue * 0.047685;
    let Z = red * 0.000088 + green * 0.07231 + blue * 0.986039;
    //Calculate the xy values from the XYZ values

    let x = X / (X + Y + Z);
    let y = Y / (X + Y + Z);

    // let X = 0.649926 * R + 0.103455 * G + 0.197109 * B;
    // let Y = 0.234327 * R + 0.743075 * G + 0.022598 * B;
    // let Z = 0.0 * R + 0.053077 * G + 1.035763 * B;

    // let x = X / (X + Y + Z);
    // let y = Y / (X + Y + Z);

    axios.put(
      "http://" +
        Config.PhilipsHueAPI +
        "/api/" +
        Config.username +
        "/lights/" +
        id +
        "/state",
      { xy: [x, y], sat: 254 }
    );
  }

  //   componentDidMount() {
  //   }

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div id="settings-popup" className={"settings-popup"}>
        <div className="settings-popup__inner" data-id={this.props.id}>
          <FontAwesomeIcon
            onClick={this.onClose}
            className="settings-popup__close"
            icon={["fas", "times"]}
          />
          <h3>{this.props.data.name}</h3>
          <p>Set brightness:</p>
          <Slider
            defaultValue={this.props.data.state.bri}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            min={1}
            max={254}
            onChange={this.setBrightness}
          />
          <p>Set color:</p>
          <SliderPicker
            width="100%"
            color={this.state.lightColor}
            onChange={this.setColor}
          />
        </div>
      </div>
    );
  }
}

export default LightSettings;
