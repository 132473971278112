import React, { Component, Fragment } from "react";
import axios from "axios";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import Config from "../../config";

import GroupCard from "./GroupCard";

class Groups extends Component {
  constructor() {
    super();
    this.state = {
      groups: []
    };
  }

  getGroups = async () => {
    try {
      const res = await axios.get(
        "http://" + Config.DomoticzAPI + "/json.htm?type=scenes"
      );
      const { data } = await res;
      const groups = data.result;

      this.setState({ groups: groups });
    } catch (e) {
      console.log(e);
    }
  };

  async componentDidMount() {
    await this.getGroups();
  }

  render() {
    const { groups } = this.state;

    return (
      <div>
        <Container>
          <Box py={6}>
            <ul>
              {groups.map((el, i) => (
                <Fragment key={i}>
                  <GroupCard id={el.idx} title={el.Name} status={el.Status} />
                </Fragment>
              ))}
            </ul>
          </Box>
        </Container>
      </div>
    );
  }
}

export default Groups;
