import React from "react";
import "babel-polyfill";
import "./App.scss";

import Tabs from "./Components/Tabs";

function App() {
  return (
    <div className="App">
      <Tabs />
    </div>
  );
}

export default App;
