import React, { Component } from "react";
import axios from "axios";

import LightSettings from "./LightSettings";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { lightGreen, grey } from "@material-ui/core/colors";

import Config from "../../config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

library.add(fas, far);

const iconStyle = {
  fontSize: "40px",
  marginBottom: "20px",
  opacity: "0.5"
};

const StyledSwitch = withStyles({
  switchBase: {
    color: grey[300],
    "&$checked": {
      color: lightGreen["A400"]
    },
    "&$checked + $track": {
      backgroundColor: lightGreen[500]
    }
  },
  checked: {},
  track: {}
})(Switch);

class LightCard extends Component {
  constructor() {
    super();
    this.state = {
      on: false,
      showModal: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  showModal = e => {
    this.setState({
      showModal: !this.state.showModal
    });
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);
  };

  handleClick = name => event => {
    this.setState({ [name]: event.target.checked });
    const el = event.target;
    const id = el.parentElement.parentElement.dataset.id;
    axios.put(
      "http://" +
        Config.PhilipsHueAPI +
        "/api/" +
        Config.username +
        "/lights/" +
        id +
        "/state",
      { on: event.target.checked }
    );
  };

  componentDidMount() {
    const reachable = this.props.reachable;
    this.setState({ on: reachable ? this.props.status : false });
  }

  componentDidUpdate(prevProps) {
    const reachable = this.props.reachable;
    // Doesn't work with reachable
    if (this.props.status !== prevProps.status) {
      this.setState({ on: reachable ? this.props.status : false });
    }
    if (this.props.reachable !== prevProps.reachable) {
      this.setState({ on: reachable ? this.props.status : false });
    }
  }

  render() {
    return (
      <Grid item xs={6} sm={4} md={4} lg={3}>
        <Paper
          className={
            "card-single reachable-" + (this.props.reachable ? "on" : "off")
          }
        >
          <div
            id="settings"
            onClick={e => {
              this.showModal();
            }}
          >
            <FontAwesomeIcon className="settings-icon" icon={["fas", "cog"]} />
          </div>
          <div className={"indicator " + (this.state.on ? "on" : "off")}></div>
          {this.state.on === true ? (
            <FontAwesomeIcon
              style={iconStyle}
              className="icon-on"
              icon={["fas", "lightbulb"]}
            />
          ) : (
            <FontAwesomeIcon
              style={iconStyle}
              className="icon-off"
              icon={["far", "lightbulb"]}
            />
          )}
          <p>{this.props.title}</p>
          <StyledSwitch
            checked={this.state.on}
            onChange={this.handleClick("on")}
            data-id={this.props.id}
            value="on"
            disabled={this.props.reachable ? false : true}
          />
          <LightSettings
            onClose={this.showModal}
            show={this.state.showModal}
            data={this.props.data}
            id={this.props.id}
          />
        </Paper>
      </Grid>
    );
  }
}

export default LightCard;
