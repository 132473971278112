import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Lights from "../Components/Lights/Lights";
import KaKu from "../Components/KaKu";
import Groups from "../Components/Groups/Groups";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    padding: theme.spacing(2),
    marginTop: "auto",
    backgroundColor: theme.palette.primary.main
  }
}));

const StyledTab = withStyles(theme => ({
  root: {
    fontSize: theme.typography.pxToRem(15)
  }
}))(props => <Tab {...props} />);

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

export default function FullWidthTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="sticky" color="secondary">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <StyledTab label="Lights" {...a11yProps(0)} />
          <StyledTab label="Groups" {...a11yProps(1)} />
          <StyledTab label="Other" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews axis="x" index={value} onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0} dir="x">
          <Lights />
        </TabPanel>
        <TabPanel value={value} index={1} dir="x">
          <Groups />
        </TabPanel>
        <TabPanel value={value} index={2} dir="x">
          <KaKu />
        </TabPanel>
      </SwipeableViews>
      {/* <footer className={classes.footer}>
        <Container maxWidth="sm">
          <Typography variant="body1">
            My sticky footer can be found here.
          </Typography>
        </Container>
      </footer> */}
    </div>
  );
}
